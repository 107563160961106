<template>
  <AddMembers
    showActions
    :loading="managedLoading"
    :showList="openAddMember"
    :newMembers="newMembers"
    :prevMembers="managedMembers"
    :defaultMemberAuthority="defaultMemberAuthority"
    @sync:showList="bool => $emit('update:openAddMember', bool)"
    @sync:newMembers="members => (newMembers = members)"
    @add:members="addMembers"
  />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AddMembers from "@/flow/views/components/commons/addMembers";

export default {
  components: { AddMembers },
  props: {
    openAddMember: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { newMembers: [] };
  },
  computed: {
    ...mapState("flowProjects", ["managedLoading"]),
    ...mapGetters("flowProjects", ["managedProject", "managedMembers"]),
    defaultMemberAuthority() {
      return this.managedProject?.defaultMemberAuthority;
    }
  },
  methods: {
    ...mapActions("flowProjects", ["inviteMembers"]),
    addMembers() {
      const params = { members: this.newMembers };
      const callback = bool => this.$emit("update:openAddMember", !bool);
      this.inviteMembers({ params, callback });
    }
  }
};
</script>
